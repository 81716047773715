<dropdown dropdownClass="header-nav-item-select nav-profile" (click)="navProfile.toggle($event)" *ngIf="user">
    <div class="toggle-wrapper d-flex align-items-center">
        <avatar [size]="35" [src]="user.media?.mediaURL"></avatar>
        <span class="fw-bold mx-1">{{user.firstName}} {{user.lastName}}</span>
        <i class="feather icon-chevron-down"></i>
    </div>
    <dropdown-menu #navProfile alignment="right">
        <div class="nav-profile-header">
            <div class="d-flex align-items-center">
                <avatar [src]="user.media?.mediaURL"></avatar>
                <div class="d-flex flex-column ms-1">
                    <span class="fw-bold text-dark">{{user.firstName}} {{user.lastName}}</span>
                    <span class="font-size-sm">{{user.email}}</span>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)" (click)='call(menuItem.item)' dropdownItem *ngFor="let menuItem of profileMenuList">
            <div class="d-flex align-items-center">
                <i class="font-size-lg mx-2" [ngClass]="menuItem.icon"></i>
                <span>{{menuItem.item}}</span>
            </div>
        </a>
    </dropdown-menu>
</dropdown>
