import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_ENDPOINT } from '@app/configs/app.config';
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';
interface User {
    id: number;
    username: string;
    password: string;
    isClient: boolean;
    token?: string;
}
const USER_AUTH_API_URL = 'login';

@Injectable()
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public partNo: any; 
    constructor(private http: HttpClient, private router:Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(payload) {
        return this.http.post<any>(`${environment.apiUrl}${USER_AUTH_API_URL}`, payload)
        .pipe(map(user => {
            if (user && user.token) {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }
            return user;
        }));
    }

    async getSession(){
        try{
            const loggedUser = await this.http.get<any>(`${environment.apiUrl}session`).toPromise();
            return loggedUser
        }
        catch(ex){
            return undefined;
        }
    }


    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login'])
    }
}