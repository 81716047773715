<div class="nav-logo mt-2">
    <logo 
        [logoType]="!collapse || quickExpand ? 'logo' : 'fold' "
        [height]="!collapse || quickExpand ? 70 : 40"
        [white]= "color === 'dark'"
        class="w-100"
    ></logo>
</div>
<hr style="width: 70%;" class="mx-auto">
<perfect-scrollbar class="side-nav-content">
    <vertical-menu-content></vertical-menu-content>
</perfect-scrollbar>
