import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class partService {

 private partNo = new BehaviorSubject<number>(0);
 currentPartNo = this.partNo.asObservable();

 constructor() {

 }
 updatePart(message: any) {
    this.partNo.next(message)
 }
}